var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailsWrap" },
    [
      _c(
        "p",
        { staticClass: "detailsTop" },
        [
          _c(
            "router-link",
            { attrs: { to: "/equip-management" } },
            [
              _c("a-icon", { attrs: { type: "rollback" } }),
              _vm._v(" 回到设备分组 "),
            ],
            1
          ),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoHome } },
            [_vm._v(" 返回主页 ")]
          ),
        ],
        1
      ),
      _c("p", { staticClass: "detailsTitle" }, [_vm._v(" 分组信息 ")]),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "200px", "margin-right": "20px" },
              model: {
                value: _vm.code,
                callback: function ($$v) {
                  _vm.code = $$v
                },
                expression: "code",
              },
            },
            [
              _c("a-select-option", { attrs: { value: "all" } }, [
                _vm._v(" 全部产品 "),
              ]),
              _vm._l(_vm.productList, function (item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.code } },
                  [_vm._v(" " + _vm._s(item.productName) + " ")]
                )
              }),
            ],
            2
          ),
          _c("a-input-search", {
            staticStyle: { width: "400px" },
            attrs: { placeholder: "请输入设备或产品名称", "enter-button": "" },
            on: { search: _vm.onSearch },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c(
        "p",
        { staticStyle: { margin: "20px 0", "text-align": "right" } },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "20px" },
              on: { click: _vm.deleteList },
            },
            [_vm._v(" 批量移出设备 ")]
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.addEquipDialog = true
                },
              },
            },
            [_vm._v(" 添加设备 ")]
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          locale: { emptyText: "暂无数据" },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          "table-layout": "auto",
          columns: _vm.columns,
          pagination: false,
          "row-key": "id",
          "data-source": _vm.data,
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoDetails(record)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDelete(record)
                      },
                    },
                  },
                  [_vm._v("移出分组")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("AddEquip", {
        attrs: { visible: _vm.addEquipDialog },
        on: {
          cancel: function ($event) {
            _vm.addEquipDialog = false
          },
          onSubmit: _vm.onSure,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }