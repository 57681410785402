<template>
    <div class="detailsWrap">
        <p class="detailsTop">
            <router-link to="/equip-management">
                <a-icon type="rollback" /> 回到设备分组
            </router-link>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </p>
        <p class="detailsTitle">
            分组信息
        </p>
        <div style="display: flex">
            <a-select v-model="code" style="width: 200px; margin-right: 20px">
                <a-select-option value="all">
                    全部产品
                </a-select-option>
                <a-select-option v-for="item in productList" :key="item.id" :value="item.code">
                    {{ item.productName }}
                </a-select-option>
            </a-select>
            <a-input-search v-model="keyword" placeholder="请输入设备或产品名称" style="width: 400px" enter-button @search="onSearch" />
        </div>
        <p style="margin: 20px 0; text-align: right">
            <a-button style="margin-right: 20px" @click="deleteList">
                批量移出设备
            </a-button>
            <a-button type="primary" @click="addEquipDialog = true">
                添加设备
            </a-button>
        </p>
        <a-table
            :locale="{emptyText: '暂无数据'}"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
            <template slot="operation" slot-scope="text, record">
                <a href="javascript:void(0);" style="margin-right: 20px" @click="gotoDetails(record)">查看</a>
                <a href="javascript:void(0);" @click="onDelete(record)">移出分组</a>
            </template>
        </a-table>
        <AddEquip :visible="addEquipDialog" @cancel="addEquipDialog = false" @onSubmit="onSure" />
    </div>
</template>

<script>
import {productList} from "@/api/product"
import AddEquip from "@/views/components/AddEquip"
const columns = [
    {
        title: '设备名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '所属产品',
        dataIndex: 'product',
        key: 'product'
    },
    {
        title: '设备状态',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: '操作',
        width: '160px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        id: 1,
        name: 'AAAAAA',
        product: 'BBBBB',
        status: '未激活'
    },
    {
        id: 2,
        name: 'AAAAAA',
        product: 'BBBBB',
        status: '离线'
    }
]
export default {
    name: "GroupDetails",
    components: {
        AddEquip
    },
    data() {
        return {
            // 当前选中的产品value值
            code: 'all',
            // 产品列表数据
            productList: [],
            // 搜索内容
            keyword: '',
            columns,
            data,
            selectedRowKeys: [],
            addEquipDialog: false
        }
    },
    created() {
        this.getProductList()
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 获取产品列表数据
        getProductList() {
            productList().then(res => {
                this.productList = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        // 分组信息中的多选事件
        onSelectChange(val) {
            this.selectedRowKeys = val
        },
        // 搜索操作
        onSearch() {},
        // 查看详情操作
        gotoDetails() {
            this.$router.push('/projectEquip-list/details?id=1')
        },
        // 移出分组操作
        onDelete() {
            this.$confirm({
                title: '移出分组',
                content: '您确定要将该设备移出分组吗？',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.$message.success('移出成功！')
                }
            })
        },
        // 批量移出操作
        deleteList() {
            if(this.selectedRowKeys.length === 0) {
                this.$message.warn('请先选中设备！')
                return
            }
            this.$confirm({
                title: '移出分组',
                content: '您确定要将设备移出分组吗？',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.$message.success('移出成功！')
                }
            })
        },
        // 确认添加设备操作
        onSure() {
            this.addEquipDialog = false
            this.$message.success('设备添加成功！')
        }
    }
}
</script>

<style lang="scss" scoped>
.detailsWrap {
    margin: 30px;
    .detailsTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .detailsTitle {
        font-size: 20px;
        font-weight: bold;
        margin-right: 20px;
    }
}
</style>
